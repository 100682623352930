import moment from 'moment'

// production
// export const host = "https://ocb-faceotp.wee.vn/api"
// dev & qc
export const host = "https://ocb-faceotp-dev.wee.vn/api"

// export const hostWebsocket = "wss://citigym.wee.vn/ws/web/notify/connect"

export const apiGetFace = (faceId) => `${host}/manage/transaction/photo?faceId=${faceId}&time=${moment().unix()}`

export const apiGetAvatar = (id) => `${host}/manage/customer/avatar?id=${id}&time=${moment().unix()}`